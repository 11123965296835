@font-face {
    font-family: 'bukra';
    font-style: normal;
    font-weight: 400;
    src: url("./29ltbukraregular.ttf") format("truetype");
}

@font-face {
    font-family: 'urw';
    font-style: normal;
    font-weight: 300;
    src: url("./URW-DIN-Arabic-Medium.ttf") format("truetype");
}

* {
    font-family: 'urw', sans-serif;
    margin: 0px;
}

.header {
    width: 100%;
    position: fixed;
    z-index: 1;
    height: 53px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(to right, #287d5a, #c3d22e);
    padding-top: 1.3em;
}

.header--logo {
    width: 18em;
    position: fixed;
    margin: 0 auto;
}

.-copy {
    margin: 0 auto;
    width: 100%;
    float: left;
    height: 32px;
    font-size: 1.5em;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.Logo-White {
    width: 14em;
    height: 60px;
    object-fit: contain;
    position: fixed;
    top: 0;
    right: 10px;
}

.Group-5 {
    object-fit: contain;
    top: 0;
    left: 0;
    position: absolute;
    width: 15em;
    height: 70px !important;
}

.add-item__input {
    width: 100%;
    height: 41px;
    border-radius: 5px;
    border: 1px solid #e4e8ee;
    background-color: #ffffff;
    outline: none;
    padding: 10px 15px;
    box-sizing: border-box;
    color: #3f4751;
    font-size: 0.8em;
    font-weight: 500;
    direction: rtl;
}

.add__btn {
    padding: 11px 50px;
    box-shadow: 0 2px 14px #348d7d6b;
    border-radius: 5px;
    border: none;
    background-color: #318e7b;
    color: #ffffff;
    font-size: 0.8em;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 26px;
    cursor: pointer;
    transition: background-color 0.5s ease-out;
    outline: none;
}

.add__btn:hover,
.add__btn:disabled {
    background-color: #7ac39624;
    color: #318e7b;
}

.add__btn-center {
    margin-bottom: 0;
}

.add-item__row {
    margin-bottom: 30px;
}

.add-item__title-wrapper {
    padding-bottom: 6px;
}

.add-item__title {
    color: #676767;
    font-size: 0.8em;
    font-weight: 600;
    float: right;
    padding: 2em 0;
}
.add-item__title2 {
    color: #676767;
    font-size: 1.2em;
    font-weight: 600;
    float: right;
    padding: 2em 0;
    width: 100%;
    text-align: center;
}

.add-item__wrapper {
    background-color: #f3f7fa;
    padding: 30px 100px;
    display: flex;
    justify-content: center;
    position: relative;
}
.add-item__wrapper2 {
    background-color: #f3f7fa;
    
    display: flex;
    justify-content: center;
    position: relative;
}

.content {
    min-height: calc(100vh - 80px);
    background-color: #f3f7fa;
}

.content2{
    background-color: #f3f7fa;
}

.svgView {
    width: 41em;
}

.add-item {
    margin-top: 3em;
    box-sizing: border-box;
    padding: 40px 159px 40px 141px;
    border-radius: 5px;
}

.error__message {
    color: #ec644b;
    font-size: 1em;
    font-weight: 500;
}

.loader {
    width: 200px;
    height: 200px;
    border-radius: 5px;
    background-color: #ffffff;
    position: fixed;
    left: 43%;
    top: 29%;
}

@media only screen and (max-width: 1220px) {
    .add-item__wrapper {
        padding: 80px 100px;
    }
}

@media only screen and (max-width: 1024px) {
    .add-item {
        padding: 40px;
    }
    .svgView {
        width: 30em;
    }
    .loader {
        left: 41%;
    }
}

@media only screen and (max-width: 680px) {
    .add-item__wrapper {
        padding: 130px 30px;
    }
    .add-item__title-main {
        font-size: 1em;
    }
    .add__btn {
        width: 100%;
    }
    .svgView {
        width: 20em;
    }
    .-copy {
        font-size: 1em;
        position: absolute;
        right: 40%;
        height: 30.1px;
        top: 4em;
        width: auto;
    }
    .Group-5 {
        display: none;        
    }
    .Logo-White {
        object-fit: contain;
        top: 16px;
    }
    .Logo-White {
        position: absolute;
        right: 34%;
        height: 30.1px;
        width: auto;
    }
    .containHead {
        width: 100%;
        height: 100px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
        background-image: linear-gradient(to right, #00659b, #009b97);
        border-bottom-left-radius: 200px;
        border-bottom-right-radius: 200px;
    }
    .header {
        box-shadow: none;
        background-image: none;
        padding-top: 0;
    }
    .info-msg {
        font-size: 0.8em;
    }
    .loader {
        left: 26%;
    }
}

@media only screen and (max-width: 360px) {
    .svgView {
        width: 15em;
    }
    .-copy {
        font-size: 1em;
        position: absolute;
        right: 40%;
        height: 30.1px;
        top: 4em;
        width: auto;
    }
    .Group-5 {
        display: none;
    }
    .header {
        box-shadow: none;
        background-image: none;
        padding-top: 0;
    }
    .containHead {
        width: 100%;
        height: 100px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
        background-image: linear-gradient(to right, #00659b, #009b97);
        border-bottom-left-radius: 200px;
        border-bottom-right-radius: 200px;
    }
    .Logo-White {
        right: 34%;
    }
    .info-msg {
        font-size: 0.6em;
    }
    .loader {
        left: 22%;
    }
}

.info-msg {
    margin: 10px 0;
    padding: 10px;
    border-radius: 3px 3px 3px 3px;
    direction: rtl;
}

.info-msg {
    color: #059;
    background-color: #BEF;
}

.imgToDownload {
    width: 100%;
}
svg {
    height: 100%;
    width: 100%;
}
